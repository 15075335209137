import {Prop, Watch} from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component'
import user from '@/store/user';
import offers from '@/store/offers';
import {getModule} from "vuex-module-decorators";
import {TUser} from "@/types/TUser";
import {ServiceOffer} from '@/services/ServiceOffer';
import {TOfferHeader} from "@/types/TOfferHeader";
import {TOffer} from "@/types/TOffer";
import {TPossibleOfferStatus} from "@/types/TPossibleOfferStatus";
import StatusOferta from "@/components/StatusOferta.vue";
import Oferta from "@/pages/Oferta/Oferta.vue"
import Cerere from "@/pages/Cerere/Cerere.vue"
import {timeUntilNow, timeUntilFutureDate} from '@/modules/utils'
import {humanStatusOffer,timeDateHuman} from '@/modules/utils'
import eventbus from "@/store/eventbus";
import { ServiceDownload } from '@/services/ServiceDownload';
import VuePdfEmbed from 'vue-pdf-embed';
import { TCertificate } from '@/types/TCertificate';
import { TFactura } from '@/types/TFactura';
import { ServiceInvoice } from '@/services/ServiceInvoice';
import { isSet } from '@vue/shared';

@Options({
    name: "Offers",
    components: {StatusOferta, Oferta, Cerere, VuePdfEmbed}
})
export default class Offers extends Vue {
    @Prop({ default: '0' }) public readonly pidOffer!: string|null;
    public offersHeaders:TOfferHeader[]=[];
    public loadingOffers = true;
    public dialogViewOferta=false;
    public selectedOfferId = '';
    public selectedOfferSlid = '';
    public selectedOfferDate = '';
    public appidToBeCancelled='';
    public appidToBeRecalled='';
    public appidToBeDeleted='';
    public dynamicComponent='';
    public inputSearchOffer = '';
    public dialogTransitionShow ='';
    public dialogTransitionHide ='';
    public userStore = getModule(user);
    public offersStore = getModule(offers);
    public EventBusStore = getModule(eventbus);
    
    public pdfTitle='';
    public fixed=false;
    public pdf='';
    public popupTitle='';
    public isLoadingOffer=false;
    public downloadingFile = false;
    public areMoreCertificates=false;
    public areMoreInvoices=false;
    public areMoreInvoicesCertificate=false;
    public certificates:Array<TCertificate> = [];
    public isLoadingInvoice=false;
    public selectedInvoices:Array<TFactura> = [];
    public widthPdf=300;

    get user(

    ): TUser {
        return this.userStore.user;
    }

    get offers():TOffer[]{
        return this.offersStore.offers;
    }

    public changeWitdhPdf(op: string){
        if(op == 'in') {
            if(this.widthPdf > 1500) {return;}
            this.widthPdf *= 1.25; 
        } else {
            if(this.widthPdf < 300) {return;}
            this.widthPdf /= 1.25; 
        }
    }

    public humanStatusOffer(status:TPossibleOfferStatus){
        return humanStatusOffer(status);
    }

    public timeDateHuman(pdate:string,pformat:string){
        return timeDateHuman?timeDateHuman(pdate,pformat):'';
    }

    public getOffersFromDB(){
        const vueInst=this;
        vueInst.$q.loading.show();
        ServiceOffer.getOffersFromDB().then(response=>{
            vueInst.$q.loading.hide();
            vueInst.loadingOffers=false;
            if(response.status=='success'){
                vueInst.offersHeaders=JSON.parse(JSON.stringify(response.offers));
            }

        })
    }

    public getOfferByIdFromDB(offerId:string){
        const vueInst=this;
        vueInst.appidToBeRecalled=offerId;
        ServiceOffer.getOffer(offerId).then(response=>{
            if(response.status=='success'){
                const idxInArr=vueInst.offersHeaders.findIndex(offer=>{
                   return offer.offerId==offerId
                })
                const newOffer={
                            dateWhenOfferExpire: response.offerHeader.dateWhenOfferExpire,
                            offerId: response.offerHeader.offerId,
                            offerSlid: response.offerHeader.offerSlid,
                            status: response.offerHeader.status,
                            trackCreationDate: response.offerHeader.trackCreationDate,
                            trackDateAccept: response.offerHeader.trackDateAccept,
                            trackDateOfertare: response.offerHeader.trackDateOfertare

                }
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                vueInst.offersHeaders[idxInArr]=newOffer;
                vueInst.appidToBeRecalled='';
            }
        })
    }

    private timeUntilNow(pStringDate: string, pformat:string){
        return timeUntilNow(pStringDate,pformat)
    }

    private timeUntilFutureDate(pStringDate: string, pformat:string){
        return timeUntilFutureDate(pStringDate,pformat)
    }

    askCancelOffer(pOfferId:string){
        const vueInst=this;
        vueInst.$q.dialog({
            title: 'Confirm',
            message: `Anulare oferta ${pOfferId}?`,
            ok: {
                flat:true,
                label: 'Da, anuleaza!',
            },
            cancel: {
                label: 'Renunt',
                flat:true
            },
            persistent: true
        }).onOk(() => {
            vueInst.appidToBeCancelled=pOfferId;
            ServiceOffer.cancelOffer(pOfferId).then(response=>{
                vueInst.appidToBeCancelled='';
                vueInst.appidToBeDeleted=pOfferId;
                if(response.status=='success'){
                    vueInst.$q.notify({
                        color: 'teal',
                        textColor: 'white',
                        icon: 'positive',
                        position: 'top',
                        timeout: 500,
                        message: response.message
                    })
                   setTimeout(function(){ vueInst.eliminaDePeEcranOfertaAnulata(pOfferId);}, 1000);
                }
            })
        })
    }

    public closeCurrentView(){
        const vueInst=this;
        //vueInst.dialogViewOferta = false;
        vueInst.EventBusStore.set_event({name:'closeCurrentView', params:null});
    }

    public closeFormViewOferta(needRefresh:boolean){
        const vueInst=this;
        vueInst.dialogViewOferta = false;
        if(needRefresh){
            ServiceOffer.getOffer(vueInst.selectedOfferId).then(response=>{
                if(response.status=='success'){
                    //extend(true, vueInst.offerHeader,  response.offerHeader);
                   const indexToBeReplaced= vueInst.offersHeaders.findIndex((poffer,pindex)=>{
                        return poffer.offerId==response.offerHeader.offerId;
                    })
                    vueInst.offersHeaders[indexToBeReplaced]=response.offerHeader;
                }
            })
        }
        vueInst.selectedOfferId='';
    }

    public onOpenOferta(pOfferId:string|null, pofferSlid:string|null , pofferDate:string|null){
        if(pOfferId && pofferSlid && pofferDate) {
            this.dynamicComponent='Oferta';
            this.selectedOfferId = pOfferId;
            this.selectedOfferSlid = pofferSlid;
            this.selectedOfferDate = pofferDate;

            if (this.$q.platform.is.mobile) {
                this.dialogTransitionShow = 'slide-right';
                this.dialogTransitionHide = 'slide-left';
            } else {
                this.dialogTransitionShow = 'slide-right';
                this.dialogTransitionHide = 'slide-left';
            }
            this.dialogViewOferta = true;
        }
    }

    public onOpenCerere(pOfferId:string|null, pofferDate:string|null){
        if(pOfferId && pofferDate) {
            this.dynamicComponent='Cerere';
            this.selectedOfferId = pOfferId;
            this.selectedOfferSlid = pOfferId;//ca sa nu mai fac variabile separate
            this.selectedOfferDate = pofferDate;
            if (this.$q.platform.is.mobile) {
            this.dialogTransitionShow = 'slide-right';
            this.dialogTransitionHide = 'slide-left';
        } else {
            this.dialogTransitionShow = 'slide-right';
            this.dialogTransitionHide = 'slide-left';
        }
        this.dialogViewOferta = true;
        }
    }

    public eliminaDePeEcranOfertaAnulata(pOfferId:string){
        const vueInst=this;
        let indexToDelete=-1;
        vueInst.offersHeaders.forEach((offer,pindex) => {
            if(offer.offerId == pOfferId){
                indexToDelete=pindex;
            }
        })
        vueInst.offersHeaders.splice(indexToDelete,1);
        vueInst.appidToBeDeleted='';
    }

    @Watch('EventBusStore.event.eventId')
    onEventIdChanged() {
        const vueInst=this;
        console.log('in Offers eventId is changed, vueInst.EventBusStore.event=%o',vueInst.EventBusStore.event)
        if(vueInst.EventBusStore.event.name=='eventNewOfferFromSales'){
            if(this.$route.name=='Offers') {
                if(vueInst.EventBusStore.event.params && vueInst.EventBusStore.event.params.offerId){
                    vueInst.getOfferByIdFromDB(vueInst.EventBusStore.event.params.offerId)
                }
            }
        }
        if(vueInst.EventBusStore.event.name=='eventCloseDialogViewOffer'){
            if(this.$route.name=='Offers') {
                vueInst.dialogViewOferta=false;
            }
        }
    }

    public downloadPDF() {
        const vueInst = this;
        const linkSource = vueInst.pdf;
        const downloadLink = document.createElement("a");
        const fileName = vueInst.pdfTitle;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    public generareOfertaPDFRO(offerSlid: string){
        const vueInst = this;
        vueInst.pdf = '';
        vueInst.pdfTitle = '';
        vueInst.popupTitle = '';
        vueInst.isLoadingOffer= true;  
        vueInst.fixed = true;  
        ServiceDownload.generareOfertaPdfLink('confirmare', offerSlid, 'ro').then(response => {
            vueInst.isLoadingOffer = false;
            if(response.status == 'success') {
                const pathPdf = response.message;
                vueInst.pdfTitle = 'Confirmare_'+offerSlid+'_'+this.userStore.user.companyName+'.pdf'
                vueInst.pdf = 'data:application/pdf;base64,'+ pathPdf +'#title='+ vueInst.pdfTitle;
                vueInst.popupTitle = 'Confirmare ' + offerSlid;
            }
        });
        
    }

    public generareOfertaPDFENG(offerSlid: string){
        const vueInst = this;
        vueInst.pdf = '';
        vueInst.pdfTitle = '';
        vueInst.popupTitle = '';
        vueInst.isLoadingOffer= true;   
        vueInst.fixed = true;
        ServiceDownload.generareOfertaPdfLink('confirmare',offerSlid, 'eng').then(response => {
            vueInst.isLoadingOffer = false;
            if(response.status == 'success') {
                const pathPdf = response.message;
                vueInst.pdfTitle = 'Confirmation_'+offerSlid+'_'+this.userStore.user.companyName+'.pdf'
                vueInst.pdf = 'data:application/pdf;base64,'+ pathPdf +'#title='+ vueInst.pdfTitle;
                vueInst.popupTitle = 'Confirmation_ ' + offerSlid;
            }
        });
        
    }

    public download_certificate(bill_nr: string){
        const vueInst=this;
        console.log(bill_nr);
        vueInst.pdf = '';
        vueInst.pdfTitle = '';
        vueInst.popupTitle = '';     
        ServiceInvoice.downloadCertificate(bill_nr).then(response=>{
            
            if(response.status=='success') { 
                console.log(response);
                if(response.countCertificates > 0) {
                    vueInst.areMoreCertificates = true;
                    console.log(response.articles);
                    vueInst.certificates = response.articles;
                    vueInst.popupTitle = 'Certificate Factura ' + bill_nr;
                } else {
                    if(isSet(response.message)) {    
                        vueInst.fixed = true;  
                        vueInst.pdfTitle = 'Certificate_'+bill_nr+'.pdf';
                        vueInst.pdf = 'data:application/pdf;base64,'+ response.message +'#title='+vueInst.pdfTitle;
                        vueInst.popupTitle = 'Certificat '+bill_nr;
                    } else {
                        vueInst.$q.notify({
                            color: 'red',
                            textColor: 'white',
                            type: 'negative',
                            icon: 'error',
                            position: 'top',
                            timeout: 1000,
                            message: 'Nu exista certificate pentru aceasta factura'
                        })
                    }
                }
            }

        })
    }

    public download_certificate_2(index: number){
        const vueInst=this;
        const certificat = vueInst.certificates[index];
        vueInst.pdf = '';
        vueInst.pdfTitle = '';
        vueInst.popupTitle = '';
        vueInst.isLoadingInvoice = true;     
        vueInst.fixed = true;  
        vueInst.pdfTitle = 'Certificate_'+certificat.ItemNo+'.pdf';
        vueInst.pdf = 'data:application/pdf;base64,'+ certificat.Certificate+'#title='+vueInst.pdfTitle;
        vueInst.popupTitle = 'Certificat '+certificat.ItemNo;
        vueInst.isLoadingInvoice = false;   
    }

    public generareCertificate(offer: TOfferHeader){
        const vueInst = this;
        vueInst.selectedOfferSlid = '';
        if(offer.invoices != null && offer.invoices.length > 0) {
            if(offer.invoices.length == 1) {
                vueInst.download_certificate(offer.invoices[0].id);
            } else {
                vueInst.areMoreInvoicesCertificate = true;
                vueInst.selectedInvoices = offer.invoices;
                vueInst.selectedOfferSlid = offer.offerSlid;
            }
        } else {
            vueInst.$q.notify({
                color: 'red',
                textColor: 'white',
                type: 'negative',
                icon: 'error',
                position: 'top',
                timeout: 1000,
                message: 'Nu exista certificate pentru aceasta oferta'
            })
        } 
    }

    public generateFacturi(offer: TOfferHeader){
        const vueInst = this;
        if(offer.invoices != null && offer.invoices.length > 0) {
            vueInst.pdf = '';
            vueInst.pdfTitle = '';
            vueInst.popupTitle = '';
            vueInst.isLoadingOffer= true;
            vueInst.selectedInvoices = offer.invoices;
            if(offer.invoices.length > 1) {
                vueInst.areMoreInvoices = true;
                vueInst.popupTitle = 'Facturi Oferta ' + offer.offerSlid; 
            } else {
                vueInst.fixed = true;
                const bill_nr = offer.invoices[0].id;
                vueInst.popupTitle = 'Factura '+ bill_nr;  
                ServiceInvoice.downloadInvoice(bill_nr).then(response=>{
                    vueInst.isLoadingOffer = false; 
                    if(response.status=='success') { 
                            vueInst.pdfTitle = 'Invoice_'+bill_nr+'.pdf';
                            vueInst.pdf = 'data:application/pdf;base64,'+ response.message +'#title='+vueInst.pdfTitle;
                    }

                });
            }
        } else {
            vueInst.$q.notify({
                color: 'red',
                textColor: 'white',
                type: 'negative',
                icon: 'error',
                position: 'top',
                timeout: 1000,
                message: 'Nu exista facturi pentru aceasta oferta'
            })
        }

    }

    public download_invoice(bill_nr: string){
        const vueInst=this;
        console.log(bill_nr);
        vueInst.fixed = true;
        vueInst.pdf = '';
        vueInst.pdfTitle = '';
        vueInst.popupTitle = 'Factura '+ bill_nr;
        vueInst.isLoadingOffer = true;      
        ServiceInvoice.downloadInvoice(bill_nr).then(response=>{
            vueInst.isLoadingOffer = false; 
            if(response.status=='success') { 
                    vueInst.pdfTitle = 'Invoice_'+bill_nr+'.pdf';
                    vueInst.pdf = 'data:application/pdf;base64,'+ response.message +'#title='+vueInst.pdfTitle;
            }

        })
    }


    public activated(){
        this.loadingOffers=true;
        this.userStore.set_page_transition('fade-in-right');
        if(this.$q.platform.is.mobile) {
            this.userStore.set_showbackbar(true);
            this.userStore.set_title_back_bar('Oferte si Comenzi');
        }
        this.getOffersFromDB();
    }

}
