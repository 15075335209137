import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28336188"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "dashboard__div__title"
}
const _hoisted_2 = { class: "category__div__content" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "subcategories"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HierarchicalChainBrowseHeader = _resolveComponent("HierarchicalChainBrowseHeader")!
  const _component_CategoryImg = _resolveComponent("CategoryImg")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_HierarchicalChainBrowseHeader, { hierarchicalChain: _ctx.hierarchicalChain }, null, 8, ["hierarchicalChain"]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.isMainCategory == true ? 'ecran-container-row' : 'ecran-container')
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (mainCateg) => {
        return (_openBlock(), _createElementBlock("div", {
          key: mainCateg.pid,
          class: _normalizeClass({ content__category__with__articles: mainCateg.isParentForArticles=='y'})
        }, [
          (mainCateg.isParentForArticles=='n')
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(mainCateg.name), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            (mainCateg.isParentForArticles=='y')
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_CategoryImg, {
                    category: mainCateg,
                    onClick: ($event: any) => (_ctx.browseCategory(mainCateg))
                  }, null, 8, ["category", "onClick"])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(mainCateg.children, (subcategory) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: subcategory.pid
                    }, [
                      _createVNode(_component_CategoryImg, {
                        category: subcategory,
                        onClick: ($event: any) => (_ctx.browseCategory(subcategory))
                      }, null, 8, ["category", "onClick"])
                    ]))
                  }), 128))
                ]))
          ])
        ], 2))
      }), 128))
    ], 2)
  ]))
}