import {Component, Prop, Watch} from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component'
import user from '@/store/user';
import nomenclatoare from '@/store/nomenclatoare';
import {getModule} from 'vuex-module-decorators';
import {ServiceAdminNomCategory} from '@/services/ServiceAdminNomCategory'
import {ServiceAdminNomProducts} from '@/services/ServiceAdminNomProducts'
import {TArticle} from "@/types/TArticle";
import {TOptionCategory} from "@/types/TOptionCategory";
import throttle from 'quasar/src/utils/throttle.js';;
import ContacteleMeleColorMetal from "@/components/ContacteleMeleColorMetal.vue";
import {EnumUM} from "@/types/EnumUM";

type TArticleStr = {
    appid:string;
    pid:string;
    categoryPid:string;
    lantHierarchyCategories: string;
    code: string;
    name:string;
    UMBase:string;
    um1: string;
    um2: string;
    um1ToUm2: number;
    isActive:string;
    withLength: string;
    withWidth: string;
    withThickness: string;
    withDiameter: string;
    withHeight: string;
    withAlloy: string;
    withType: string;
    sizeLength:number|null;
    sizeWidth:number|null;
    sizeThickness:number|null;
    sizeDiameter:number|null;
    sizeHeight:number|null;
    sizeAlloy: string|null;
    sizeType: string|null;
}

@Options({
    name: "EditArticle",
    components: {}
})
export default class EditArticle extends Vue {
    @Prop({ default: '0' }) public readonly pid!: string|null;
    //@Prop() public fnOnSaveData!: (userid:string)=>void;
    @Prop() public closeHandler!: (needRefresh:boolean)=>void;
    public ajaxLoadingData=true;
    public storeNomenclatoare = getModule(nomenclatoare);
    public article:TArticle={
                                appid:'',
                                pid:'',
                                categoryPid:'',
                                lantHierarchyCategories:'',
                                code: '',
                                name:'',
                                UMBase:'',
                                um1:'',
                                um2: '',
                                um1ToUm2: 1,
                                isActive:false,
                                withLength: false,
                                withWidth: false,
                                withThickness: false,
                                withDiameter: false,
                                withHeight: false,
                                withAlloy: false,
                                withType: false,
                                sizeLength:0,
                                sizeWidth:0,
                                sizeThickness:0,
                                sizeDiameter:0,
                                sizeHeight:0,
                                sizeAlloy:'',
                                sizeType:''
                            };
    public urlUserImgProfileByAppid = '';
    declare public $refs: any;
    public filteredOptionsCategories:TOptionCategory[]=[];
    public userStore = getModule(user);
    public nomenclatoareStore = getModule(nomenclatoare);

    public goToView(pRouteViewName: string ) {
        this.$router.push({ name: pRouteViewName })
    }

    get optionsUM(): EnumUM[] {
        return this.storeNomenclatoare.optionsUM;
    }

    public onSubmit(){
        const vueInst=this;
        //alert('de discutat, nu stiu daca ar trebui sa modifice ceva in portaL, poate trebuie sa vina totul din Sales?!')

        vueInst.$q.loading.show()
        ServiceAdminNomProducts.postProduct(vueInst.article).then(response=>{
            vueInst.$q.loading.hide();
            if(response.status=='success'){
                vueInst.closeHandler(true);
            }
        });

    }

    getCategoryDetailsAndSetSizes(pCategoryPid:string){
        const vueInst=this;
        console.log('getCategoryDetailsAndSetSizes=%o',pCategoryPid);
        const pCategory=vueInst.optionsCategories.find(category=>{
            return category.pid==pCategoryPid;
        })
        console.log('getCategoryDetailsAndSetSizes pCategory=%o',pCategory)
        if(pCategory) {

            vueInst.article.lantHierarchyCategories='';

            vueInst.article.um1 = '';
            vueInst.article.um2 = '';
            vueInst.article.um1ToUm2 = 1;

            vueInst.article.sizeLength = null;
            vueInst.article.sizeWidth = null;
            vueInst.article.sizeThickness = null;
            vueInst.article.sizeDiameter = null;
            vueInst.article.sizeHeight = null;
            vueInst.article.sizeAlloy = null;
            vueInst.article.sizeType = null;

            vueInst.article.withLength = (pCategory.withLength == 'y' ? true : false);
            vueInst.article.withWidth = (pCategory.withWidth == 'y' ? true : false);
            vueInst.article.withThickness = (pCategory.withThickness == 'y' ? true : false);
            vueInst.article.withDiameter = (pCategory.withDiameter == 'y' ? true : false);
            vueInst.article.withHeight = (pCategory.withHeight == 'y' ? true : false);
            vueInst.article.withAlloy = (pCategory.withAlloy == 'y' ? true : false);
            vueInst.article.withType = (pCategory.withType == 'y' ? true : false);
        }
    }

    private get optionsCategories():TOptionCategory[]{
      return this.nomenclatoareStore.optionsCategories;
    }

    public getListDataCategoryThenArticleFromDB(){
        const vueInst=this;
        ServiceAdminNomCategory.getNomCategorylistData().then(response=>{
            console.log('response=%o',response)
            if(response.status=='success'){
                vueInst.nomenclatoareStore.set_categories(JSON.parse(JSON.stringify(response.listDataCategories)));
                vueInst.filteredOptionsCategories=vueInst.optionsCategories;
                if(vueInst.pid && vueInst.pid!='0'){
                    vueInst.getArticleFromDB();
                }else{
                    vueInst.$q.loading.hide();
                }
            }
        })
    }

    public getArticleFromDB(){
        const vueInst=this;
        if(vueInst.pid && vueInst.pid!='0'){
            ServiceAdminNomProducts.getArticleFromDB(vueInst.pid).then(response=>{
                vueInst.$q.loading.hide();
                const item :TArticleStr= JSON.parse(JSON.stringify(response.product)) as TArticleStr;
                if(response.status=='success'){
                    vueInst.article= {
                        appid:item.appid,
                        pid:item.pid,
                        categoryPid:item.categoryPid,
                        lantHierarchyCategories:item.lantHierarchyCategories,
                        code: item.code,
                        name:item.name,
                        UMBase:item.UMBase,
                        um1: item.um1,
                        um2: item.um1,
                        um1ToUm2: item.um1ToUm2,
                        isActive:item.isActive=='y'?true:false,
                        withLength: item.withLength=='y'?true:false,
                        withWidth: item.withWidth=='y'?true:false,
                        withThickness: item.withThickness=='y'?true:false,
                        withDiameter: item.withDiameter=='y'?true:false,
                        withHeight: item.withHeight=='y'?true:false,
                        withAlloy: item.withAlloy=='y'?true:false,
                        withType: item.withType=='y'?true:false,
                        sizeLength:item.sizeLength,
                        sizeWidth:item.sizeWidth,
                        sizeThickness:item.sizeThickness,
                        sizeDiameter:item.sizeDiameter,
                        sizeHeight:item.sizeHeight,
                        sizeAlloy: item.sizeAlloy,
                        sizeType: item.sizeType
                    }
                }
            })
        }
    }

    public focusOnArticleName(){
        this.$refs.refArticleName.focus();
    }

    public focusOnCategorySelection(){
        this.$refs.refArticleCategorySelection.focus();
    }

    public focusOnArticleType(){
        this.$refs.refArticleType.focus();
    }


    filterFnCategories (val:string, update:any) {
        const vueInst=this;

        if (val === '') {
            update(() => {
                vueInst.filteredOptionsCategories=vueInst.optionsCategories;
            })
            return
        }

        update(() => {
            const needle = val.toLowerCase()
            vueInst.filteredOptionsCategories = vueInst.optionsCategories.filter(v => v.lantHierarchyCategories.toLowerCase().indexOf(needle) > -1)
        })
    }

    public onReset(){
        this.article={
            appid:'',
            pid:'',
            categoryPid:'',
            lantHierarchyCategories:'',
            code: '',
            name:'',
            UMBase:'',
            um1:'',
            um2: '',
            um1ToUm2: 1,
            isActive:false,
            withLength: false,
            withWidth: false,
            withThickness: false,
            withDiameter: false,
            withHeight: false,
            withAlloy: false,
            withType: false,
            sizeLength:0,
            sizeWidth:0,
            sizeThickness:0,
            sizeDiameter:0,
            sizeHeight:0,
            sizeAlloy:'',
            sizeType:''
        }
    }

    public created(){
        const vueInst=this;
        vueInst.$q.loading.show();
        vueInst.onReset();
        vueInst.getListDataCategoryThenArticleFromDB();
    }
}
